




















import {Component, Vue, Inject, Prop, Watch} from 'vue-property-decorator';
import Util from '../../../../lib/util'
import AbpBase from '../../../../lib/abpbase'
import ContainerSize from '../../../../store/entities/containercontext/containersize'

@Component
export default class EditContainerSize extends AbpBase {
    @Prop({type: Boolean, default: false}) value: boolean;
    @Prop({type: Boolean, default: false}) public toEdit: boolean;
    containerSize: ContainerSize = new ContainerSize();

    created() {
    }

    save() {
        (this.$refs.containerSizeForm as any).validate(async (valid: boolean) => {
            if (valid) {
                await this.$store.dispatch({
                    type: this.toEdit ? 'containersize/update' : 'containersize/create',
                    data: this.containerSize
                });
                (this.$refs.containerSizeForm as any).resetFields();
                this.$emit('save-success');
                this.$emit('input', false);
            }
        })
    }

    cancel() {
        (this.$refs.containerSizeForm as any).resetFields();
        this.$emit('input', false);
    }

    visibleChange(value: boolean) {
        if (!value) {
            this.$emit('input', value);
        } else {
            if (this.toEdit) {
                this.containerSize = Util.extend(true, {}, this.$store.state.containersize.editContainerSize);
            }
            else {
                this.containerSize = new ContainerSize();
            }
        }
    }

    checkSizeInput(rule, value, callback) {
        if (value == null) {
            return callback(new Error(this.L('FieldIsRequired', undefined, this.L('Size'))));
        }
        const parsed = parseInt(value);
        if (isNaN(parsed)) {
            return callback(new Error(this.L('Size is not a number')));
        }
        return callback();
    }

    containerSizeRule = {
        size: [{required: true, validator: this.checkSizeInput, trigger: 'blur'}]
    }
}
